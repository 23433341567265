import React from 'react';
import './App.css';

import NoSsr from '@material-ui/core/NoSsr';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider, Theme, Typography, Link, Grid, Chip, Card, CardContent, Button, Avatar, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { createMuiTheme, createStyles, makeStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GitHubIcon from '@material-ui/icons/GitHub';

import foto from './asantos.jpeg';

export default function App() 
{
    const appTema = createMuiTheme({ 
        palette: 
        {
            type: "dark",
            primary: {main: green[400]},
            secondary: {main: orange[400]},
        },
        spacing: 8,
        props: {
            // MuiFormLabel: { style:{marginBottom: "16px", fontWeight: "bold"} }
            // MuiChip:   { style:{marginRight: "8px", marginTop: "8px"} },
            // MuiButton: { style:{marginRight: "8px", marginTop: "8px"} },
            // MuiButton: { style:{ margin: "8px 8px 8px 0"} },
            // MuiChip:   { style:{ margin: "8px 8px 8px 0" } },
            MuiCard:   { style:{ height: "100%" } }
        }
    });

    const classes = useStyles(appTema);

    return (
        <NoSsr>
            <ThemeProvider theme={appTema}>
                <CssBaseline />
                
                <Container component="main" maxWidth="md" className={classes.mainContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h2" component="h1" align="center">
                                <Grid container alignItems="center" justify="center">
                                    <Grid item>
                                        <Avatar alt="Alexandre Santos Foto" src={foto} style={{width: "68px", height: "68px", marginRight: "16px"}} />
                                    </Grid>
                                    <Grid item>
                                        Alexandre Santos
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1} justify="center">
                                <Grid item>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        href="https://github.com/alsantos123?tab=repositories" 
                                        target="_blank"
                                        startIcon={<GitHubIcon />}
                                    >Github</Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        href="https://www.linkedin.com/in/alexandre-almeida-santos/" 
                                        target="_blank"
                                        startIcon={<LinkedInIcon />}
                                    >LinkedIn</Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        href="mailto:alsantos123@gmail.com" 
                                        target="_blank"
                                        startIcon={<AlternateEmailIcon />}
                                    >Email</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1} justify="center">
                                <Grid item xs={12}><Typography align="center" component="h2">Experiências</Typography></Grid>
                                <Grid item><Chip label="Desenvolvedor" /></Grid>
                                <Grid item><Chip label="Analista" /></Grid>
                                <Grid item><Chip label="Arquiteto" /></Grid>
                                <Grid item><Chip label="Node.js" /></Grid>
                                <Grid item><Chip label="Next.js" /></Grid>
                                <Grid item><Chip label="React" /></Grid>
                                <Grid item><Chip label="Typescript" /></Grid>
                                <Grid item><Chip label="Objective-C" /></Grid>
                                <Grid item><Chip label="C# .NET" /></Grid>
                                <Grid item><Chip label="PHP" /></Grid>
                                <Grid item><Chip label="Drupal" /></Grid>
                                <Grid item><Chip label="Wordpress" /></Grid>
                                <Grid item><Chip label="MySQL" /></Grid>
                                <Grid item><Chip label="SQLite" /></Grid>
                                <Grid item><Chip label="DynamoDB" /></Grid>
                                <Grid item><Chip label="RDS" /></Grid>
                                <Grid item><Chip label="EC2" /></Grid>
                                <Grid item><Chip label="Application Load Balancer" /></Grid>
                                <Grid item><Chip label="Auto Scaling Group" /></Grid>
                                <Grid item><Chip label="API Gateway" /></Grid>
                                <Grid item><Chip label="Lambda" /></Grid>
                                <Grid item><Chip label="CloudFront" /></Grid>
                            </Grid>
                        </Grid>

                        

                        <Grid item sm={4} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography component="h2">
                                        <Link 
                                            href="https://www.certmetrics.com/amazon/public/badge.aspx?i=9&t=c&d=2019-12-11&ci=AWS00304081" 
                                            target="_blank" 
                                        >
                                            AWS Certified Cloud Practitioner
                                        </Link>
                                    </Typography>
                                    <Typography variant="caption">2019</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography component="h2">
                                        <Link 
                                            href="https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2017-09-13&ci=AWS00304081" 
                                            target="_blank" 
                                        >
                                            AWS Certified Solutions Architect - Associate
                                        </Link>
                                    </Typography>
                                    <Typography variant="caption">2017</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography component="h2">
                                        Sistema de Informação
                                    </Typography>
                                    <Typography variant="caption">
                                        Universidade Estácio de Sá  <br />
                                        Completo - 2017
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography component="h2">
                                        Ciência da Computação 
                                    </Typography>
                                    <Typography variant="caption">
                                        Universidade Gama Filho <br />
                                        Incompleto - 2015
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography component="h2">
                                        Formação DBA Oracle
                                    </Typography>
                                    <Typography variant="caption">
                                        Infnet <br />
                                        2009
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    
                        <Grid item xs={12}>
                            <Grid container spacing={1} justify="center">
                                <Grid item xs={12}><Typography align="center" component="h2">Projetos relevantes</Typography></Grid>
                                <Grid item><Chip label="AWS Blogs - Moderna" /></Grid>
                                <Grid item><Chip label="GO - Ancar" /></Grid>
                                <Grid item><Chip label="Shoppings - Ancar (Nova América)" /></Grid>
                                <Grid item><Chip label="Institucional - Ancar" /></Grid>
                                <Grid item><Chip label="Mall Digital - Argo" /></Grid>
                                <Grid item><Chip label="Unique - Brasil Brokers" /></Grid>
                                <Grid item><Chip label="Estacionamento - Shopping Anália Franco" /></Grid>
                                <Grid item><Chip label="App iOS Vereda - Moderna" /></Grid>
                                <Grid item><Chip label="LLV - Multiplan" /></Grid>
                                <Grid item><Chip label="Shoppings - Multiplan (Barra Shopping)" /></Grid>
                                <Grid item><Chip label="Intranet - IBRE FGV" /></Grid>
                                <Grid item><Chip label="App iOS Revista Conjuntura - IBRE FGV" /></Grid>
                                <Grid item><Chip label="Banco de Currículos - FIRJAN" /></Grid>
                                <Grid item><Chip label="Curta Criativo - FIRJAN" /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                            {/* <div class="job">
								<h2>GM5 Informática</h2>
								<h3>Desenvolvedor</h3>
								<h4>AGO 2010 - Atual</h4>
								<p>Desenvolvimento de sistemas e grandes portais empresariais.</p>
							</div>
						
							<div class="job">
								<h2>Fábrica Digital</h2>
								<h3>Desenvolvedor</h3>
								<h4>ABR 2010 - JUL 2010</h4>
								<p>
									Desenvolvimento de Portais com Drupal, WordPress e Publique!.<br />
									Desenvolvimento com Lua, PHP, Python.
								</p>
							</div>

							<div class="job">
								<h2>GPS Eventos</h2>
								<h3>Desenvolvedor e Administrador de Banco de Dados</h3>
								<h4>MAI 2008 - ABR 2010</h4>
								<p>Desenvolvimento de sistemas com PHP, .Net, Python/Django e outros.<br />
								   Análise de Sistemas.<br />
								   Adminsitrador de Banco de Dados. <br />
								   Administrador de Servidores.<br />
                   				   Viagem para Angola como DBA.
								</p>
							</div> */}

                    {/* <Typography variant="h5" component="h2" gutterBottom>
                    {'Pin a footer to the bottom of the viewport.'}
                    {'The footer will move as the main element of the page grows.'}
                    </Typography>
                    <Typography variant="body1">Sticky footer placeholder.</Typography> */}


                </Container>
                
            </ThemeProvider>
        </NoSsr>
    );
}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles(
        {
            mainContainer: 
            {
                minWidth: "300px",
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
                '& .MuiGrid-item': {  }
            }

        });
});
